button,
a {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: var(--main-bg-color);
}

.title {
  text-align: center;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: var(--default-font-size);
}

@media (max-width: 600px) {
  .App {
    font-size: var(--mobile-font-size);
  }
}
