.flex {
  flex: 1;
}

.users {
  background-color: var(--main-bg-color);
  padding: 1em 2em 2em;
}

.users__h1 {
  color: var(--white);
  margin-top: 0;
  margin-bottom: 0.5em;
  text-align: center;
}

.users__user-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  max-height: 70vh;
  overflow: scroll;
}

.users__user-list__li {
  width: 47.5%;
  margin: 0.5em 0;
}

@media (max-width: 1400px) {
  .users__user-list {
    justify-content: center;
  }

  .users__user-list__li {
    min-width: 100%;
  }
}
