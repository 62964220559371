.nav-bar {
  position: absolute;
  right: 0;
  top: 0;
}

.nav-bar__ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

.nav-bar__ul-container {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--main-bg-color);
}

.nav-bar__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--main-bg-color);
  opacity: 0.5;
  display: none;
}

.burger-menu {
  display: none;
}

.nav-bar__nav-link {
  padding: 0.8em;
  background-color: var(--main-bg-color);
  color: var(--white);
  border: 2px solid var(--main-bg-color);
  height: 3.5em;
  box-sizing: border-box;
  font-size: 16px;
}

a.nav-bar__nav-link {
  text-decoration: none;
  display: inline-block;
}

.nav-bar__nav-link:hover {
  background-color: var(--white);
  color: var(--main-bg-color);
}

@media (max-width: 600px) {
  .nav-bar {
    top: 16px;
    z-index: 10;
  }

  .nav-bar.mobile-visible {
    top: 0;
  }

  .nav-bar.mobile-hidden {
    width: unset;
    height: unset;
  }

  .nav-bar__ul-container.mobile-visible {
    width: 50vw;
    height: 100vh;
    display: block;
  }

  .nav-bar__ul-container.mobile-hidden {
    width: unset;
    height: unset;
    display: none;
  }

  .nav-bar__overlay.mobile-visible {
    display: block;
  }

  .nav-bar__overlay.mobile-hidden {
    display: none;
  }

  .nav-bar__ul.mobile-hidden,
  .burger-menu.mobile-hidden {
    display: none;
  }

  .nav-bar__ul.mobile-visible,
  .burger-menu.mobile-visible {
    display: block;
  }
}
