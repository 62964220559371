.summary {
  background: var(--white);
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.summary span:first-child {
  min-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

@media only screen and (max-width: 600px) {
  .summary {
    padding: 1em;
  }
}
