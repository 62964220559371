button.submit {
  width: 100%;
  background-color: var(--main-bg-color);
  border: 2px solid var(--main-bg-color);
  padding: 0.5em;
  color: var(--white);
  font-weight: 700;
}

button.submit:hover {
  background-color: var(--white);
  color: var(--main-bg-color);
}
