.orders {
  display: flex;
  gap: 1em;
}

.orders__section {
  flex: 2;
  display: flex;
  gap: 1em;
}

.orders__summary {
  flex: 1;
  display: flex;
  gap: 5em;
  flex-direction: column;
  justify-content: center;
}

.orders__summary__invoice {
  background-color: var(--main-bg-color);
  position: relative;
}

.orders__summary__invoice ul {
  padding: 0 2em;
  margin: 4em 0;
  min-height: 5em;
  max-height: 10em;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.orders__summary__invoice__item,
.orders__add-order__customer-order-group__total {
  color: var(--primary-text);
  list-style: none;
  display: flex;
}

.orders__summary__invoice__item > span:first-child,
.orders__add-order__customer-order-group__total > span:first-child {
  flex: 2 1;
  background-color: var(--white);

  display: flex;
  justify-content: space-between;
  padding: 0.2em 1em;
}

.orders__summary__invoice__item > span:last-child,
.orders__add-order__customer-order-group__total > span:last-child {
  flex: 1 1;
  background-color: var(--secondary);
  padding: 0.2em;
  text-align: center;
}

.orders__summary__invoice__title,
.orders__add-order__title {
  background-color: var(--white);
  position: absolute;
  padding-block: 0.5em;
  width: 50%;
  left: 25%;
  top: -1em;
  text-align: center;
  font-weight: 700;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}

.orders__add-order__title__select {
  text-align: center;
  width: 100%;
  border: none;
  appearance: none;
}

.orders__summary__invoice__total {
  background-color: var(--white);
  position: absolute;
  bottom: -2.5em;
  left: 10%;
  width: 80%;
  padding-block: 0.2em;
  text-align: center;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}

.orders__summary__invoice__total span {
  font-weight: 500;
  font-size: 2em;
}

.orders__summary__invoice__total p {
  margin-top: 0;
  font-weight: 500;
}

.orders__summary__confirmation {
  background-color: var(--main-bg-color);
  color: var(--white);
  text-align: center;
  padding: 1em;
}

.orders__summary__confirmation p {
  margin-top: 0;
  font-weight: 500;
}

.orders__add-order__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.orders__add-order__container > div:first-child {
  margin-bottom: 2em;
}

.orders__add-order {
  display: flex;
  align-items: center;
}

.orders__add-order > div {
  position: relative;
  height: 15vh;
  background-color: var(--main-bg-color);
  width: 100%;
  padding: 3em 0.5em;
}

.orders__add-order__info {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.orders__add-order__customer-order-group__info__price span:first-child {
  background-color: var(--secondary);
}

.orders__add-order__customer-order-group__info__price span:last-child {
  background-color: var(--white);
}

.orders__add-order__info .orders__add-order__customer-order-group {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.orders__add-order__info .orders__add-order__customer-order-group:first-child {
  font-size: 2em;
}

.orders__add-order__info select {
  height: 3em;
}

.orders__add-order__customer-order-group__info__quantity {
  display: flex;
}

.orders__add-order__customer-order-group__info__quantity span {
  background-color: var(--white);
  padding-inline: 0.3em;
}

.orders__add-order__controls {
  position: absolute;
  bottom: -1.5em;
  left: 25%;
  width: 50%;
  padding-block: 0.2em;
  text-align: center;
}

.orders__add-order__restock-order-group__info__input-group {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.orders__add-order__restock-order-group {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  font-size: 11px;
}

.orders__add-order__restock-order-group .input {
  border: none;
  background-color: var(--secondary);
  width: 50%;
  padding-inline: 5px;
}

.orders__add-order__restock-order-group label {
  width: 50%;
  padding-inline: 5px;
}

.error {
  color: var(--white);
  background-color: var(--danger);
  padding: 5px;
}

@media (max-width: 1400px) {
  .orders__section {
    flex-direction: column-reverse;
  }
}

@media (max-width: 600px) {
  .orders .products {
    display: none;
  }

  .orders__section {
    gap: 5em;
  }
}
