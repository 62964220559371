.button-container {
  display: flex;
  gap: 0.5em;
}

.button-container button {
  width: 50%;
  padding: 0.5em;
  color: var(--main-bg-color);
  background-color: var(--white);
  border: none;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}

.orders__summary__confirmation .button-container button {
  font-size: 1.5em;
}

.orders__add-order__controls .button-container button {
  font-size: 1em;
}
