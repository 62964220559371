.login__form {
  box-shadow: 0 5px 5px #eee;
  padding: 1em;
  margin: 0 auto;
}

.login__form h2 {
  margin: 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
}
